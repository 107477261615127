// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract:
    'This book is about the very early days of Auroville based on interviews made in 1997 with Aurovilians who lived here between 1968 and 1973. The book is authored by Janet Fearn and published by Prisma Books.',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "covers/img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <AboutPageSchema />
    <ContactPageSchema />
    <div
      className="container"
      style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <h1>About this book</h1>
      <p>
        <i>Divine Love</i> is the only thing. Sri Aurobindo has explained it in
        Savitri. Only when Divine Love has manifested in all its purity will
        everything yield. It’s the only thing that can do it. It will be the
        great Victory.
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p>
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
      <h2>Web book, this format</h2>
      <p>
        The web book offers content in a simple and minimal interface with only
        a few interactive elements. You can navigate between sections either by
        clicking on the CONTENT button in the header, or by clicking on
        navigational buttons in the footer. You can also move between sections
        by clicking on the LEFT and RIGHT arrows on your keyboard.
      </p>
      <p>
        You have the choice between{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, nav: 'standard' })
          }}
          to="#"
          aria-label="Standard Navigation"
        >
          STANDARD NAVIGATION
        </Link>
        , or{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, nav: 'fixed' })
          }}
          to="#"
          aria-label="Fixed Navigation"
        >
          FIXED NAVIGATION
        </Link>
        .&nbsp;Furthermore you have the choice between{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, mode: 'light' })
          }}
          to="#"
          aria-label="Light Mode"
        >
          LIGHT MODE
        </Link>
        ,{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, mode: 'dark' })
          }}
          to="#"
          aria-label="Dark Mode"
        >
          DARK MODE
        </Link>
        , and{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, mode: 'sepia' })
          }}
          to="#"
          aria-label="SEPIA Mode"
        >
          SEPIA MODE
        </Link>
        .
      </p>
      <p>
        <i>Divine Love</i>&nbsp;is published as part of an emerging method of
        publishing being developed by Prisma Books. The idea is to deliver the
        high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        You can buy the paperback version of the book <i>Divine Love</i> at a
        fixed price. The author and the publisher have also chosen to offer the
        book to read online without a pay-wall or advertisement. This is not for
        free, but available on a Gift-Economy and Pay-What-Feels-Right basis. It
        is an invitation to give what feels right for the value you've received
        from the book and/or the support you want to give us to continue doing
        our work. Publishing in this manner is an experiment in abundance where
        we trust that when we give, we will also receive. We want you to enjoy
        the book, learn from the book, but also support the book. If it was
        worth your time, please consider contributing.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
